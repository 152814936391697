import { defineStore } from "pinia";
import axios from "axios";

export const useStore = defineStore('store', {
  state: () => {
    return { 

      getBalanceLoading: true,
      Card: '',
      YouMoney: '',
      All: '',
      rate: '',

      user: [],
      auth: false,
      money: [],
      accounts: [],

      // axios
      axiosHeaders: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      },
      axiosMultipartHeaders: {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      },
      
    }
  },
  actions: {
    unixDate(date) {
      const timeInMs = Date.now()
      const milliseconds = date * 1000
      const dateObject = new Date(milliseconds)
      const humanDateFormat = dateObject.toLocaleString()

      const seconds = Math.round((timeInMs - milliseconds) / 1000)
      const minutes = Math.round(seconds / 60)
      const hours = Math.round(minutes / 60)

      if(seconds < 60) {
        return seconds + ' сек. назад'
      }else if(seconds >= 60 && minutes < 60) {
        return minutes + ' мин. назад'
      }else if(minutes >= 60 && minutes < 1440) {
        return hours + ' ч. назад'
      }else{
        return humanDateFormat
      }
    },
    logout() {
      axios.post(process.env.VUE_APP_API_URL, {
        handler: 'logout',
        token: localStorage.getItem('token')
      })
      localStorage.clear()
      this.user = ''
      this.auth = false
      // localStorage.clear()
      // this.$router.push('/login')
    },
    getBalance() {
      this.getBalanceLoading = true
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getBalance"
      }, this.axiosHeaders)
      .then((response) => {
        if (response.data.result != "error") {
          this.Card = response.data.Card
          this.YouMoney = response.data.YouMoney
          this.All = response.data.All
          this.rate = response.data.rate
        }
        this.getBalanceLoading = false
      })
      .catch(() => {
        this.getBalanceLoading = false
      })
    }
  }
})