<template>
  <div>
    <h1 class="font-normal uppercase mb-5 text-gray-500">{{ title }}</h1>
  </div>
</template>

<script>
export default {

  props: {
    title: String
  }

}
</script>