<template>
  <div>
    <div class="flex mb-2 gap-2">
      <div class="shadow-sm bg-white w-full rounded p-3">
        <div class="text-slate-500 text-sm">Card</div>
        <div v-if="!store.getBalanceLoading" class="text-xl">{{ store.Card }}</div>
        <div v-if="store.getBalanceLoading" class="text-xl animate-bounce">0</div>
      </div>
      <div class="shadow-sm bg-white w-full rounded p-3">
        <div class="text-slate-500 text-sm">YooMoney</div>
        <div v-if="!store.getBalanceLoading" class="text-xl">{{ store.YouMoney }}</div>
        <div v-if="store.getBalanceLoading" class="text-xl animate-bounce">0</div>
      </div>
      <div class="shadow-sm bg-white w-full rounded p-3">
        <div class="text-slate-500 text-sm">Total</div>
        <div v-if="!store.getBalanceLoading" class="text-xl">{{ store.All }}</div>
        <div v-if="store.getBalanceLoading" class="text-xl animate-bounce">0</div>
      </div>
      <div class="shadow-sm bg-white w-full rounded p-3">
        <div class="text-slate-500 text-sm flex items-center gap-2">
          <img src="../img/tether.png" width="20" alt="">
          <div>Tether USDT</div>
        </div>
        <div v-if="!store.getBalanceLoading" class="flex justify-between items-end text-xl">
          <div class="w-full">${{ Math.round(store.All / store.rate) }}</div>
          <div class="w-auto text-sm text-slate-400">
            {{ store.rate }}
          </div>
        </div>
        <div v-if="store.getBalanceLoading" class="text-xl animate-bounce">0</div>
      </div>
    </div>

    <div class="flex mb-2 gap-2 text-center">
      <div class="w-full">
        <router-link to="/allTime" class="text-blue-400 block w-full border p-2.5 rounded mb-3"><i class="bi bi-bar-chart-line"></i> За всё время</router-link>
      </div>
      <div class="w-full">
        <router-link :to="'/year/' + getYear()" class="text-blue-400 block w-full border p-2.5 rounded mb-3"><i class="bi bi-calendar-check"></i> Текущий год</router-link>
      </div>
    </div>

  </div>

</template>

<script>
import { useStore } from '@/stores/store'
export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  mounted() {
    this.store.getBalance()
  },
  methods: {
    getYear() {
      const date = new Date()
      return date.getFullYear()
    }
  }
}
</script>