import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router/router'
import './css/index.css'
import Toaster from '@meforma/vue-toaster'

const pinia = createPinia()
const app = createApp(App)

pinia.use(({ store }) => {
  store.$router = markRaw(router)
});

app.use(router)
app.use(pinia)
app.use(Toaster, {
  position: "bottom-right",
})
app.mount('#app')