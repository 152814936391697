<template>
  <div>
    <div class="project-container mx-auto p-5">
      <div v-if="!store.auth">
        <PageLogin />
      </div>
      <div v-if="store.auth">
        <NavBar />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/stores/store'
import PageLogin from '@/views/PageLogin'
import NavBar from '@/inc/NavBar'
import axios from 'axios'

export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    PageLogin, NavBar
  },
  beforeMount() {
    this.checkAuth()
  },
  mounted() {
    setInterval(() => {
      if(this.store.auth) {
        this.checkAuth()
      }
    }, 30000)
  },
  methods: {
    checkAuth() {

      let token = localStorage.getItem('token');

      if(token) {

        axios.post(process.env.VUE_APP_API_URL, {
          handler: 'checkToken',
          token: token
        }, this.store.axiosHeaders)
        .then((response) => {
          if(response.data.result === 'ok') {
            this.store.user = response.data.user
            if(response.data.token) {
              localStorage.setItem('token', response.data.token)
            }
            this.store.auth = true
          }else{
            this.store.logout()
            this.$toast.show(response.data.msg)
          }
        })
        .catch((error) => {
          this.$toast.show(error.message)
          this.isLoading = false
        })

      }else{
        this.store.logout()
      }

    }
  }
}
</script>

<style>
  .project-container {
    max-width: 850px;
  }
</style>