<template>
  <div>
    <PageTitle title="Профиль пользователя" />

    <div v-if="isLoading" class="animate-bounce text-slate-500">Загрузка...</div>

    <div v-if="!isLoading">
      <div class="mt-2 text-xl">{{ user.first_name + ' ' + user.last_name }}</div>
      <div>Email: {{ user.email }}</div>
      <div class="mb-7">Дата регистрации: {{ store.unixDate(user.created_unix) }}</div>

      <div v-if="!userForm">
        <button @click="userForm = true" class="text-blue-400 w-full border p-2.5 rounded mb-7">Настройки</button>
      </div>
      
      <div v-if="userForm" class="mb-7 shadow-sm border bg-white p-5 rounded">
        <InputVue v-model.trim="first_name" label="Имя" />
        <InputVue v-model.trim="last_name" label="Фамилия" />
        <InputVue v-model.trim="email" label="Email" />
        <InputVue v-model.trim="password" label="Сменить пароль" />
        <button v-if="!saveStatus" @click="saveSettings" class="p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Сохранить</button>
        <button v-if="saveStatus" class="animate-bounce p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Сохраняем...</button>
        <button @click="userForm = false" class="p-3 mt-3 font-medium w-full bg-slate-100 hover:bg-slate-200 text-slate-500 hover:text-slate-700 rounded">Закрыть</button>
      </div>


      <PageTitle title="Последняя активность" />

      <div v-for="item in actions" :key="item.id" class="w-full">
        <div class="w-full p-3 rounded bg-white shadow mb-1">
          <div class="flex">
            <div class="flex-1 text-slate-700">
              {{ item.text }}
            </div>
            <div class="text-slate-500">
              {{ store.unixDate(item.created_at) }}
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/inc/PageTitle'
import { useStore } from '@/stores/store'
import InputVue from '@/inc/InputVue'
export default {
  data() {
    return {
      userForm: false,
      user: [],
      actions: [],
      isLoading: true,
      saveStatus: false,
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    }
  },
  components: {
    PageTitle, InputVue
  },
  setup() {
    const store = useStore()
    return {
      store,
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.getUser()
      },
      deep: true
    }
  },
  mounted() {
    this.getUser()
    this.getActions()
    document.title = 'Профиль пользователя'
  },
  methods: {
    getUser() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getUser",
        id: this.$route.params.id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.user = response.data.user
          this.first_name = response.data.user.first_name
          this.last_name = response.data.user.last_name
          this.email = response.data.user.email
        }
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        this.$toast.show(error.message)
      })
    },
    getActions() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getActions",
        user_id: this.$route.params.id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.actions = response.data.actions
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
      })
    },
    saveSettings() {
      this.saveStatus = true
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        handler: "updateUser",
        id: this.$route.params.id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.user.first_name = this.first_name
          this.user.last_name = this.last_name
          this.user.email = this.email
          this.$toast.success(response.data.msg)
        }
        this.saveStatus = false
      })
      .catch((error) => {
        this.saveStatus = false
        this.$toast.show(error.message)
      })
    }
  }
}
</script>