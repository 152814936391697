<template>
  <div>

    <div class="w-full mb-4">
      <router-link to="/allTime" class="text-blue-400 block w-full border p-2.5 rounded mb-3 text-center"><i class="bi bi-arrow-left"></i> Статистика за все время</router-link>
    </div>

    <PageTitle :title="'Статистика за ' + $route.params.id +  ' год'" />

    <div v-if="isLoading" class="animate-bounce text-slate-500">Загрузка...</div>

    <div v-for="item in months" :key="item.id">
      <div class="w-full p-3 rounded bg-white shadow mb-1">
        <div class="flex gap-2">
          <div class="flex-1">
            <router-link :to="'/year/' + $route.params.id + '/month/' + item.month" class="text-blue-400">{{ monthName(item.month) }}</router-link>
          </div>
          <div class="flex-1 text-green-600" title="Доходы">
            <i class="bi bi-arrow-up"></i> {{ item.plus / 100 }}
          </div>
          <div class="flex-1 text-red-500" title="Расходы">
            <i class="bi bi-arrow-down"></i> {{ item.minus / 100 }}
          </div>
          <div class="flex-1 text-blue-500" title="Профит">
            <i class="bi bi-briefcase"></i> {{ (item.plus - item.minus) / 100 }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/inc/PageTitle'
import { useStore } from '@/stores/store'
import axios from 'axios'

export default {
  data() {
    return {
      isLoading: true,
      months: []
    }
  },
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    PageTitle
  },
  mounted() {
    this.getMonths()
  },
  methods: {
    getMonths() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getMonths",
        year: this.$route.params.id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.months = response.data.months
        }
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        this.$toast.show(error.message)
      })
    },
    monthName(number) {
      number = Math.abs(number) - 1
      var arr = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
      return arr[number]
    }
  }
}
</script>