<template>
  <div>
    <div class="flex mb-2">
      <button v-if="type === 'plus'" class="shadow-sm rounded-l p-2.5 bg-green-500 w-full text-white"><i class="bi bi-check-lg"></i> Доходы</button>
      <button v-if="type === 'plus'" @click="type = 'minus'" class="shadow-sm rounded-r p-2.5 bg-slate-200 w-full">Расходы</button>
      <button v-if="type === 'minus'" @click="type = 'plus'" class="shadow-sm rounded-l p-2.5 bg-slate-200 w-full">Доходы</button>
      <button v-if="type === 'minus'" class="shadow-sm rounded-r p-2.5 bg-red-500 w-full text-white"><i class="bi bi-check-lg"></i> Расходы</button>
    </div>

    <div class="flex g-2 mb-2">
      <div class="w-full">
        <input type="number" 
          ref="amount"
          v-model.trim="amount"
          class="shadow-sm border w-full p-2.5 outline-none rounded focus:border-indigo-400" 
          placeholder="Сумма"
        >
      </div>
      <div class="w-full ml-2">
        <div class="flex">
          <button v-if="account === 'YouMoney'" class="shadow-sm rounded-l p-2.5 bg-blue-500 w-full text-white"><i class="bi bi-check-lg"></i> YouMoney</button>
          <button v-if="account === 'YouMoney'" @click="account = 'Card'" class="shadow-sm rounded-r p-2.5 bg-slate-200 w-full">Card</button>
          <button v-if="account === 'Card'" @click="account = 'YouMoney'" class="shadow-sm rounded-l p-2.5 bg-slate-200 w-full">YouMoney</button>
          <button v-if="account === 'Card'" @click="account = 'Card'" class="shadow-sm rounded-r p-2.5 bg-blue-500 w-full text-white"><i class="bi bi-check-lg"></i> Card</button>
        </div>
      </div>
    </div>

    <div class="flex mb-1">
      <div class="w-auto">
        <input type="date" 
          v-model="created_date"
          class="shadow-sm border w-full p-2.5 outline-none rounded focus:border-indigo-400"
        >
      </div>
      <div class="w-full ml-2">
        <input v-model.trim="comment" class="mb-2 shadow-sm border w-full p-2.5 outline-none rounded focus:border-indigo-400" placeholder="Комментарий">
      </div>
    </div>

    <div class="mb-7">
      <button v-if="!addMoneyStatus" @click="addMoney" class="p-2.5 bg-indigo-500 border border-indigo-500 hover:bg-indigo-600 hover:border-indigo-600 rounded text-white shadow-sm w-full">Добавить</button>
      <button v-if="addMoneyStatus" class="animate-bounce p-2.5 bg-indigo-500 border border-indigo-500 hover:bg-indigo-600 hover:border-indigo-600 rounded text-white shadow-sm w-full">Добавляем...</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useStore } from '@/stores/store'
export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  data() {
    return {
      type: 'plus',
      account: 'YouMoney',
      amount: '',
      comment: '',
      created_date: new Date().toISOString().substr(0, 10),
      addMoneyStatus: false
    }
  },
  methods: {
    addMoney() {

      if(this.amount) {

        this.addMoneyStatus = true

        axios.post(process.env.VUE_APP_API_URL, {
          token: localStorage.getItem('token'),
          handler: 'addMoney',
          amount: this.amount,
          type: this.type,
          account: this.account,
          comment: this.comment,
          created_date: this.created_date,
        }, this.store.axiosHeaders)
        .then((response) => {
          
          if(response.data.result === 'error'){
            this.$toast.show(response.data.msg)
          }else{
            this.store.money = response.data.money
            this.amount = ''
            this.comment = ''
            this.$toast.success(response.data.msg)
            this.store.getBalance()
          }

          this.addMoneyStatus = false
        })
        .catch((error) => {
          this.$toast.show(error.message)
          this.addMoneyStatus = false
        })

      }

    },
  }
}
</script>