<template>
  <div class="md:mt-40">
    <div class="md:w-96 mx-auto shadow-sm bg-white rounded-xl border p-10">
      <PageTitle title="Авторизация" />
      <InputVue v-model="email" label="Email" id="email" />
      <InputVue type="password" v-model="password" id="password" label="Пароль" />
      <button v-if="isLoading" class="p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Авторизация...</button>
      <button v-if="!isLoading" @click="login" class="p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Войти</button>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/inc/PageTitle'
import InputVue from '@/inc/InputVue'
import axios from 'axios'
import { useStore } from '@/stores/store'

export default {
  setup() {
    const store = useStore()
    return {
      store,
    }
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: ''
    }
  },
  components: {
    PageTitle,
    InputVue
  },
  mounted() {
    document.title = 'Авторизация'
  },
  methods: {
    login(){
      this.isLoading = true
      axios.post(process.env.VUE_APP_API_URL, {
        handler: 'login',
        email: this.email,
        password: this.password
      }, this.store.axiosHeaders)
      .then((response) => {
        this.isLoading = false
        if(response.data.result === 'error'){
          this.$toast.show(response.data.msg)
        }else{
          localStorage.setItem('token', response.data.token)
          this.store.user = response.data.user
          this.store.auth = true
          this.$toast.success(response.data.msg)
          // setTimeout(() => {
          //   this.$router.push('/app')
          // }, 100)
        }
      })
      .catch((error) => {
        this.isLoading = false
        this.$toast.show(error.message)
      })
    }
  }
}
</script>