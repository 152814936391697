<template>
  <div>
    <label v-if="label" class="text-gray-600 block mb-1 text-sm font-medium">{{ label }}</label>
    <input 
      :type="type" 
      class="border w-full font-normal p-2.5 placeholder:text-gray-400 rounded mb-4 outline-none focus:border-indigo-400"
      :value="modelValue"
      :placeholder="placeholder"
      :id="id"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>