<template>
  <div>
    <div class="text-slate-500 mb-5 font-medium"><i class="bi bi-wallet2"></i> Последние транзакции <span v-if="store.money" class="bg-blue-400 rounded text-sm shadow p-1 text-white">{{ store.money.length }}</span></div>

    <div v-if="isLoading" class="animate-bounce text-slate-500">Загрузка...</div>

    <div v-if="!isLoading">

      <div v-for="item in store.money" :key="item.id" class="w-full">
        <MoneyItem :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useStore } from '@/stores/store'
import MoneyItem from './MoneyItem.vue'
export default {
  setup() {
    const store = useStore()
    return {
      store,
    }
  },
  data() {
    return {
      isLoading: true
    }
  },
  mounted() {
    this.loadLastMoney();
  },
  methods: {
    loadLastMoney() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "lastMoney"
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "ok") {
            this.store.money = response.data.money
            this.isLoading = false
        }
        else {
            this.$toast.show(response.data.msg)
            this.isLoading = false
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
        this.isLoading = false
      })
    },
  },
  components: { MoneyItem }
}
</script>