<template>
  <div>
    <PageTitle title="Статистика по годам" />

    <div v-if="isLoading" class="animate-bounce text-slate-500">Загрузка...</div>

    <div v-for="item in years" :key="item.id">
      <div class="w-full p-3 rounded bg-white shadow mb-1">
        <div class="flex gap-2">
          <div class="flex-1">
            <router-link :to="'/year/' + item.year" class="text-blue-400">{{ item.year }}</router-link>
          </div>
          <div class="flex-1 text-green-600" title="Доходы">
            <i class="bi bi-arrow-up"></i> {{ item.plus / 100 }}
          </div>
          <div class="flex-1 text-red-500" title="Расходы">
            <i class="bi bi-arrow-down"></i> {{ item.minus / 100 }}
          </div>
          <div class="flex-1 text-blue-500" title="Профит">
            <i class="bi bi-briefcase"></i> {{ (item.plus - item.minus) / 100 }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/inc/PageTitle'
import { useStore } from '@/stores/store'
import axios from 'axios'
export default {
  data() {
    return {
      isLoading: true,
      years: []
    }
  },
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    PageTitle
  },
  mounted() {
    this.getYears()
  },
  methods: {
    getYears() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getYears"
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.years = response.data.years
        }
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        this.$toast.show(error.message)
      })
    }
  }
}
</script>