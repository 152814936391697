import { createWebHistory, createRouter } from "vue-router";
import PageDashboard from "@/views/PageDashboard"
import UserPage from "@/views/UserPage"
import UsersPage from "@/views/UsersPage"
import AllTime from "@/views/AllTime"
import YearPage from "@/views/YearPage"
import MonthPage from "@/views/MonthPage"

const routes = [
  {path: "/", name: "home", component: PageDashboard },
  {path: "/user/:id", component: UserPage },
  {path: "/users", component: UsersPage },
  {path: "/allTime", component: AllTime },
  {path: "/year/:id", component: YearPage },
  {path: "/year/:year/month/:month", component: MonthPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;