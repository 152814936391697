<template>
  <div>
    <div class="w-full p-3 rounded bg-white shadow mb-1">
      <div class="flex gap-2">
        <div class="flex-1">
          <div v-if="item.type === 'plus'" class="text-green-700">
            <i class="bi bi-plus"></i>
            {{ item.amount / 100 }}
          </div>
          <div v-if="item.type === 'minus'" class="text-red-500">
            <i class="bi bi-dash"></i>
            {{ item.amount / 100 }}
          </div>
        </div>
        <div class="flex-1 text-slate-500 text-sm">
          {{ item.created_date }}
        </div>
        <div class="flex-1 text-slate-500 text-sm">
          {{ item.account }}
        </div>
        <div class="flex-1 text-slate-500 text-sm">
          <router-link class="text-blue-400" :to="'/user/' + item.creator_id">{{ item.creator_name }}</router-link>
        </div>
        <div class="flex-1 text-slate-500 text-sm px-3">
          {{ item.comment }}
        </div>
        <div class="w-auto">
          <button @click="deleteMoney(item.id)" class="text-slate-300 hover:text-red-400"><i class="bi bi-trash"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useStore } from '@/stores/store'
export default {
  props: {
    item: Object
  },
  setup() {
    const store = useStore()
    return {
        store,
    }
  },
  methods: {
    deleteMoney(id) {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "deleteMoney",
        id: id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }
        else {
          this.store.money = this.store.money.filter(i => i.id !== id)
          this.$toast.success(response.data.msg)
          this.store.getBalance()
        }
        this.addMoneyStatus = false
      })
      .catch((error) => {
        this.$toast.show(error.message)
        this.addMoneyStatus = false
      })
    }
  }
}
</script>