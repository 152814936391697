<template>
  <div>

    <BusinessStats />
    <AddMoneyForm />
    <MoneyList />

  </div>
</template>

<script>
import axios from 'axios'
import { useStore } from '@/stores/store'
import BusinessStats from '@/inc/BusinessStats'
import AddMoneyForm from '@/inc/AddMoneyForm'
import MoneyList from '../inc/MoneyList'

export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    BusinessStats,
    AddMoneyForm,
    MoneyList
  },
  mounted() {
    document.title = 'Finance'
  },
  methods: {
    loadAccounts(){
      axios.post(process.env.VUE_APP_API_URL, {
        // token: localStorage.getItem('token'),
        handler: 'accounts'
      }, this.store.axiosHeaders)
      .then((response) => {
        if(response.data.result === 'ok') {
          this.money = response.data.money
          this.isLoading = false
        }else{
          this.$toast.show(response.data.msg)
          this.isLoading = false
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
        this.isLoading = false
      })
    },
  }
}
</script>