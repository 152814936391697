<template>
  <div>
    <div class="flex gap-2 mb-5">
      <div class="w-full">
        <router-link class="text-slate-500" :to="'/'"><i class="bi bi-house"></i></router-link>
      </div>
      <div class="w-full">
        <router-link class="text-slate-500" :to="'/users'"><i class="bi bi-people"></i></router-link>
      </div>
      <div class="w-full">
        <router-link class="text-slate-500" :to="'/user/' + store.user.id"><i class="bi bi-person"></i> {{ store.user.first_name }}</router-link>
      </div>
      <div class="w-auto">
        <button @click="logout" class="text-slate-500"><i class="bi bi-box-arrow-right"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/stores/store'
export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  methods: {
    logout() {
      this.$toast.success('&#9995;&#128513; Всего доброго')
      this.store.logout()
    }
  }
}
</script>