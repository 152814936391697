<template>
  <div>
    <PageTitle title="Пользователи" />

    <div v-if="!userCreateForm">
      <button @click="userCreateForm = true" class="text-blue-400 w-full border p-2.5 rounded mb-3">Создать пользователя</button>
    </div>

    <div v-if="userCreateForm" class="mb-10 shadow-sm border bg-white p-5 rounded">
      <InputVue v-model.trim="first_name" label="Имя" />
      <InputVue v-model.trim="last_name" label="Фамилия" />
      <InputVue v-model.trim="email" label="Email" />
      <InputVue v-model.trim="password" label="Пароль" />
      <button v-if="!createStatus" @click="createUser" class="p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Создать</button>
      <button v-if="createStatus" class="animate-bounce p-3 font-medium w-full bg-indigo-500 hover:bg-indigo-600 text-white rounded">Создаем пользователя...</button>
      <button @click="userCreateForm = false" class="p-3 mt-3 font-medium w-full bg-slate-100 hover:bg-slate-200 text-slate-500 hover:text-slate-700 rounded">Отмена</button>
    </div>

    <div v-for="user in users" :key="user.id">
      <div class="w-full p-3 rounded bg-white shadow mb-1">
        <div class="flex">
          <div class="flex-1">
            <router-link :to="'/user/' + user.id" class="text-blue-400">{{ user.first_name + ' ' + user.last_name }}</router-link>
          </div>
          <div class="flex-1">
            {{ user.email }}
          </div>
          <div class="flex-1">
            online {{ store.unixDate(user.last_visit) }}
          </div>
          <div class="w-auto">
            <button @click="deleteUser(user.id)" class="text-slate-300 hover:text-red-400"><i class="bi bi-trash"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/stores/store'
import axios from 'axios'
import PageTitle from '@/inc/PageTitle'
import InputVue from '@/inc/InputVue'
export default {
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  data() {
    return {
      users: [],
      userCreateForm: false,
      createStatus: false,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    }
  },
  components: {
    PageTitle, InputVue
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "getUsers"
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "ok") {
            this.users = response.data.users
            this.isLoading = false
        }
        else {
            this.$toast.show(response.data.msg)
            this.isLoading = false
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
        this.isLoading = false
      })
    },
    createUser() {
      this.createStatus = true
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        handler: "createUser",
        id: this.$route.params.id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.$toast.success(response.data.msg)
          this.userCreateForm = false
          this.getUsers()
        }
        this.createStatus = false
      })
      .catch((error) => {
        this.createStatus = false
        this.$toast.show(error.message)
      })
    },
    deleteUser(id) {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "deleteUser",
        id: id
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "error") {
          this.$toast.show(response.data.msg)
        }else {
          this.$toast.success(response.data.msg)
          this.users = this.users.filter(i => i.id !== id)
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
      })
    }
  },
}
</script>