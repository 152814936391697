<template>
  <div>

    <div class="w-full mb-4">
      <router-link :to="'/year/' + $route.params.year" class="text-blue-400 block w-full border p-2.5 rounded mb-3 text-center"><i class="bi bi-arrow-left"></i> Назад</router-link>
    </div>

    <div class="flex">
      <div><PageTitle :title="'Транзакции за ' + monthName($route.params.month) +  ' ' + $route.params.year" /></div>
      <div class="ml-2"><span v-if="money" class="bg-blue-400 rounded text-sm shadow p-1 text-white">{{ money.length }}</span></div>
    </div>

    <div v-if="isLoading" class="animate-bounce text-slate-500">Загрузка...</div>

    <div v-if="!isLoading">
      <div v-for="item in money" :key="item.id" class="w-full">
        <MoneyItem :item="item" />
      </div>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/inc/PageTitle'
import { useStore } from '@/stores/store'
import axios from 'axios'
import MoneyItem from '@/inc/MoneyItem'

export default {
  data() {
    return {
      isLoading: true,
      money: []
    }
  },
  setup(){
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    PageTitle, MoneyItem
  },
  mounted() {
    this.loadMoneyMonth();
  },
  methods: {
    loadMoneyMonth() {
      axios.post(process.env.VUE_APP_API_URL, {
        token: localStorage.getItem("token"),
        handler: "loadMoneyMonth",
        year: this.$route.params.year,
        month: this.$route.params.month
      }, this.store.axiosHeaders)
      .then((response) => {
        if (response.data.result === "ok") {
          this.money = response.data.money
          this.isLoading = false
        }
        else {
          this.$toast.show(response.data.msg)
          this.isLoading = false
        }
      })
      .catch((error) => {
        this.$toast.show(error.message)
        this.isLoading = false
      })
    },
    monthName(number) {
      number = Math.abs(number) - 1
      var arr = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Ноябрь',
        'Декабрь'
      ]
      return arr[number]
    }
  }
}
</script>